<template>
  <div>
    <Header></Header>
    <div class="content">
      <!-- <img src="../assets/static/images/img_about2.png" class="img_qa" alt="" /> -->
      <div class="bread_nav flex-box">
        <div class="bread fs16 col3">
          当前位置：<router-link to="/">首页</router-link>><a href="javascript:;">协议内容</a>
        </div>
      </div>
      <div class="about_page">
        <div class="detail_box m1440 flex-box flex-col-start">
          <div class="flex-grow-1">
            <div class="fs36 fwb pb30">{{title}}</div>
            <div class="fs20 col6 pb40">
              <div v-html="detail.richText"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detail: {},
      title:''
    };
  },
  created() {
     var type =  this.$route.query.type;
     if(type=='SERVICE_AGREEMENT'){
				this.title = '服务协议'
			}else if(type=='PRIVACY_POLICY'){
				this.title = '隐私政策'
			}else{
				this.title = '免责声明'
			}
    this.get_about(type);
  },
   methods: {
      //获取协议内容
   get_about(type) {
      this.$api.apiLanguage({contentKeys:type}).then((data_res) => {
        if (data_res.resCode == 0) {
          this.detail = data_res.root[0]
        } else {
          this.$message.error(data_res.resMsg);
        }
      });
    },
   }
};
</script>
